<script lang="ts">
    import clsx from "clsx";
    import type { GetImageResult } from "astro";

    import FoldableMenuLink from "./FoldableMenuLink.svelte";

    export let openImg: GetImageResult;
    export let closeImg: GetImageResult;
    export let linkTopImg: GetImageResult;
    export let linkParticipantsImg: GetImageResult;
    export let linkMedleyImg: GetImageResult;

    let isOpen = false;

    function toggle() {
        isOpen = !isOpen;
    }
</script>

<div class="sp:hidden inline-block">
    <div class="fixed z-50 top-[4px] right-[10px]">
        <button on:click={toggle} aria-label="Open foldable menu">
            <img
                class="w-[40px]"
                src={isOpen ? closeImg.src : openImg.src}
                alt="Open foldable menu"
            />
        </button>
    </div>
</div>

<div
    class={clsx(
        "fixed flex inset-0 bg-black transition-[visibility,opacity] h-full duration-200 ease-in-out",
        isOpen ? "visible opacity-100" : "invisible opacity-0",
    )}
>
    <div class="my-auto">
        <FoldableMenuLink image={linkTopImg} alt="トップ" href="/" {toggle} />
        <FoldableMenuLink
            image={linkParticipantsImg}
            alt="参加者一覧"
            href="/participants"
            {toggle}
        />
        <FoldableMenuLink
            image={linkMedleyImg}
            alt="パート表"
            href="/medley"
            {toggle}
            isLast
        />
    </div>
</div>
